header {
    width: 100vw;
    height: 80px;
    background: none;
    margin: 0;
    padding: 0 80px;
    box-sizing: border-box;

    // display: flex;
    // justify-content: space-between;
    // align-items: center;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    > * {
        align-self: center;
        justify-self: center;
        &:first-child {
            justify-self: left;
        }
        &:last-child {
            justify-self: right;
        }
    }

    --header-color: var(--bg-color);
    z-index: 150;
    &.dark {
        --header-color: black;
    }
    &.fixed {
        position: fixed;
        top: 0;
    }
    &.shadow {
        background-color: var(--bg-color);
    }
    * {
        margin: 0;
        background: none;
        font-family: var(--font);
        font-weight: 400;
    }
    h1 {
        font-size: 24px;
        line-height: 33px;
        color: var(--header-color);
        cursor: pointer;
    }
    button {
        padding: 0;
        border: none;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: var(--header-color);
        cursor: pointer;
        img {
            cursor: pointer;
            &:first-child {
                width: 28px;
                margin-right: 16px;
                margin-top: -6px;
            }
            &:last-child {
                width: 18px;
                margin-left: 8px;
                margin-top: -2px;
            }
        }
    }
}
@media (max-width: 767px) {
    header {
        height: 48px;
        padding: 0 16px;
        &.dark {
            background-color: rgba(255, 255, 255, 0.75);
        }
        button {
            img {
                height: 16px;
                width: auto !important;
                margin: 0 !important;
            }
            h3 {
                display: none;
            }
        }
    }
}
