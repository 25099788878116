.menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: max(40vw, 475px);
    // background-color: blue;
    padding: 80px 80px 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    opacity: 0;
    transition-duration: 0.4s;
    &.open {
        opacity: 1;
        transition-duration: 0.4s;
        transition-delay: 0.6s;
    }
    .pages {
        padding: 0;
        display: flex;
        flex-direction: column;
        > * {
            color: white !important;
            text-decoration: none;
            position: relative;
            cursor: pointer;
            margin-bottom: 8px;
            white-space: nowrap;
            opacity: 0.5;
            font-family: var(--font);
            &:hover {
                opacity: 1;
            }
        }
        &:not(:hover) {
            > * {
                opacity: 1;
            }
        }
    }
    .bottom {
        position: absolute;
        bottom: 24px;
        left: 80px;
        line-height: 16px;
    }
}

@media (max-width: 767px) {
    .menu{
        padding-left:24px;
        .bottom{
            left:24px;
        }
    }
  }