@import url("https://fonts.googleapis.com/css2?family=Scope+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "Broadway";
  src: url("broadway_regular-webfont.woff2") format("woff2"),
    url("broadway_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  --font-3: "Scope One", serif;
  --font: "Broadway", serif;
  --font-2: "Montserrat", serif;
  --bg-color: white;
  margin: 0;
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  width: 100vw;
  height: 100vh;
  padding: 0;
  overflow-x: hidden;
  white-space: pre-line;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.grand-titre {
  font-weight: 400;
  font-size: 64px;
  line-height: 89px;
  font-family: var(--font);
}
.titre {
  font-family: var(--font-2);
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
}
.soustitre {
  font-weight: 400;
  font-size: 48px;
  line-height: 66px;
  font-family: var(--font);
}
.sous-titre-2 {
  font-family: var(--font-2);
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}
.paragraphe-high {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  font-family: var(--font-2);
}
.paragraphe {
  font-family: var(--font-2);
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: justify;
}
.paragraphe-low {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  font-family: var(--font-2);
}
.paragraphe-condensed {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  font-family: var(--font-2);
}
.sub-paragraphe {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  font-family: var(--font-2);
}
.shadow {
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
}
.shadow-2 {
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
}

.block-scroll {
  overflow: hidden;
}
html,
#root,
.noscrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
html::-webkit-scrollbar,
#root::-webkit-scrollbar,
.noscrollbar::-webkit-scrollbar {
  display: none;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* background-color: purple; */
}
body {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  background-color: black;
}

@media (max-width: 767px) {
  .grand-titre {
    font-family: var(--font-2);
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
  }
  .soustitre {
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
  }
  .paragraphe {
    font-family: var(--font);
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    text-align: justify;
  }
  .titre {
    font-family: var(--font-2);
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 350px) {
  .grand-titre {
    font-family: var(--font-2);
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
  }
}
