.main {
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  background-color: black;
  overflow-x: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  transition-duration: 1s;
  transition-timing-function: ease;
  > *:not(footer) {
    background-color: var(--bg-color);
  }
  &.pushed {
    background-color: rgb(10, 10, 10);
    height: 90vh;
    width: 90vw;
    left: max(40vw, 600px);
    bottom: 24px;
    border-radius: 10px;
    transition-duration: 1s;
    transition-timing-function: ease;
  }
}
@media (max-width: 767px) {
  .main.pushed {
    left: 450px;
  }
}
